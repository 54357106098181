import React from 'react';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var img1 = require('./../../images/video-bg.jpg');

class OurValue extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('/assets/js/masonary.js');

    };

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b30 bg-white">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Why</span> Choose Us</h2>
                                            </div>
                                            <p>At M3 Couriers, our values represent the base of our business. We are committed to ensuring the timely arrival of your shipments consistently. We prioritise customer satisfaction and aim to surpass your expectations with each interaction. We prioritise transparency and integrity in all of our interactions. Our commitment to efficiency ensures that the delivery process is seamless and trouble-free. These principles motivate our commitment to delivering exceptional courier services you can rely on.
                                            </p>
                                            
                                            <NavLink to={"/contact"}>
                                                <button type="submit" className="site-button btn-effect ">
                                                        Contact Us
                                                    </button>
                                            </NavLink>
                                          
                                           
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                  
                                  
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15 text-primary"><CountUp end={450} duration={5} /></div>
                                        <h4 className="m-tb0">Active Experts</h4>
                                    </div>
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15  text-primary"><CountUp end={670} duration={5} /></div>
                                        <h4 className="m-tb0">Satisfied Clients</h4>
                                    </div>
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15 text-primary"><CountUp end={1500} duration={5} /></div>
                                        <h4 className="m-tb0">Project Complete</h4>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                    <div className="p-b0">
                                        <div className="mt-box">
                                            <h3 className="m-t0"><span className="font-weight-100"> We Bring Over</span><span className="text-primary">  15 Years</span>  of Courier Expertise</h3>
                                        </div>
                                        <span className="progressText text-black"><b>Same-Day Delivery Efficiency</b></span>
                                        <div className="progress mt-probar-1  m-b30 m-t10">
                                            <div className="progress-bar bg-primary " role="progressbar" aria-valuenow={98} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="98%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Medical Transport Reliability</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={99} aria-valuemin={10} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="99%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Overnight Courier Services</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Exhibition Transport Expertise</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={99} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="99%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Regular Routes Consistency</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={97} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="97%" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                <strong>Why Choose Us</strong>
                    </div>
                <div className="modal fade" id="myModal2" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/34741214' />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurValue;