import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript('/assets/js/masonary.js');
    }

    render() {
        return (
            <>
                <div className={(this.props.bgcolor !== '') ? `header-nav navbar-collapse collapse ${this.props.bgcolor}` : 'header-nav navbar-collapse collapse'}>
                    <ul className="nav navbar-nav">
                        <li>
                            <NavLink 
                                to="/" 
                                className={({ isActive }) => isActive ? 'active' : ''}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="#" 
                                className={({ isActive }) => isActive ? '' : ''}
                            >
                                Services
                            </NavLink>
                            <ul className="sub-menu">
                                <li>
                                    <NavLink 
                                        to="/same-day-courier" 
                                        className={({ isActive }) => isActive ? 'active' : ''}
                                    >
                                        Same Day Courier
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        to="/medical-sector-transport" 
                                        className={({ isActive }) => isActive ? 'active' : ''}
                                    >
                                        Medical Sector Transport
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        to="/overnight-courier" 
                                        className={({ isActive }) => isActive ? 'active' : ''}
                                    >
                                        Overnight Courier
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        to="/exhibition-transport-set-up" 
                                        className={({ isActive }) => isActive ? 'active' : ''}
                                    >
                                        Exhibition Transport & Set up
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        to="/regular-routes" 
                                        className={({ isActive }) => isActive ? 'active' : ''}
                                    >
                                        Regular Routes
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        to="/hgv-heavy-goods-transport" 
                                        className={({ isActive }) => isActive ? 'active' : ''}
                                    >
                                        HGV (Heavy Goods Transport)
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink 
                                to="/pharmaceutical" 
                                className={({ isActive }) => isActive ? 'active' : ''}
                            >
                                Pharmaceutical
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/about" 
                                className={({ isActive }) => isActive ? 'active' : ''}
                            >
                                About us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/our-fleet" 
                                className={({ isActive }) => isActive ? '' : ''}
                            >
                                Our Fleet
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/gallery" 
                                className={({ isActive }) => isActive ? 'active' : ''}
                            >
                                Gallery
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/contact" 
                                className={({ isActive }) => isActive ? 'active' : ''}
                            >
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default Navigation;
