import React from 'react';
import Header from '.././../Common/Header';
import Footer from '.././../Common/Footer';
import Banner from '.././../Elements/Banner';
import AboutCourierService from '../../Elements/AboutCourierService';
import AboutCourierService2 from '../../Elements/AboutCourierService2';
import ServiceBenefits from '../.././Elements/ServiceBenefits';
import OurWorkingProcess from '../../Elements/OurWorkingProcess';
import WhyChooseUs from '../../Elements/WhyChooseUs';
import AboutCourierService3 from '../../Elements/AboutCourierService3';


var bnrimg = require('.././../../images/banner/banner2.png');

const aboutCourierService1 = [
    {
        titlePart1: "Reliable and Professional ",
        titlePart2: "Transport Solutions",
        image: require('.././../../images/banner/shippeo_1.webp'),
      
        description: "At M3 Couriers, we specialise in HGV (Heavy Goods Transport). We provide reliable, quick, reasonably priced services for companies throughout the UK. Our fleet includes 18-ton trucks, articulated lorries, and crane HIABs, which help us move heavy and large items carefully. Our drivers are CPC-certified and follow UK road safety and transport rules. Each vehicle has GPS tracking, tachographs, and strong load-securing technology. This guarantees we follow the regulations, examine everything in real-time, and treat your belongings carefully. We can assist with moving construction materials, industrial gear, commercial products, or big objects. Our bespoke transport solutions are made to fit your requirements. We always prioritise safety, efficiency, and value."
    },
   
];
const aboutCourierService2 = [
    {
        titlePart1: "What is HGV ",
        titlePart2: "(Heavy Goods Transport)?",
        image: require('.././../../images/Hiab 2.png'),
       
        description: "HGV transportation is the transportation of large, heavy goods using specially designed long-distance vehicles with plenty of cargo capacity. These trucks keep cargo safe, use less fuel, and weigh more than 3.5 tonnes; they are also excellent at maintaining stability.HGVs keep things running smoothly in construction, logistics, manufacturing, and stores. They have machinery, construction supplies, and delicate handling required items. These vehicles have weight restrictions, employ GPS monitoring and have strong safety features. These trucks have weight limits, use GPS tracking, and have good safety features. This helps them avoid problems, get there faster, and keep the cargo safe. HGVs are really important for businesses that need to move lots of big things."
    },
   
];
const aboutCourierService3 = [
    {
        titlePart1: "Get a Custom HGV Transport ",
        titlePart2: "Solution Today!",
        image: require('.././../../images/18 tonne 2.jpg'),
        
        description: "At M3 Couriers, we know every business has special transport needs. We, therefore, provide HGV solutions for many kinds of goods and sectors. Our fleet includes 18-tonne trucks, articulated lorries, and HIAB crane vehicles, perfect for big, heavy-duty transportation. We focus on heavy haulage, precise HIAB lifting, and services across the nation. To keep things safe, we follow all UK safety rules. Our vehicles have EU-standard tachographs, advanced telematics, and systems to secure loads. This helps with better fuel use, safe cargo, and tracking. With top-notch technology expert drivers, we offer smooth transport services at good prices. Contact us today to talk about your HGV transport needs. "
    },
   
];
const whyChooseUsPoints = [
    {
        title: "Experienced Team ",
        description: "– Our HGV drivers are skilled and licensed. They have many years of experience with all kinds of goods."
    },
    {
        title: "24/7 Availability",
        description: "– We work day and night to ensure we meet every delivery on time."
    },
    {
        title: "Reliability and Efficiency:",
        description: "Count on M3 Couriers for timely and efficient transportation. Our dedicated team and advanced tracking systems ensure that your materials arrive at your destination on schedule."
    },
    {
        title: "GPS Tracking",
        description: "– Our vehicles have real-time tracking. This lets our clients see where their deliveries are at any time."
    },
    {
        title: "Safe & Secure",
        description: " – We use careful methods for loading, unloading, and transporting goods. This helps us prevent damage and delays."
    },
    {
        title: "Customer Support",
        description: " – Our helpful team is always ready to help you book your transport needs."
    }
];
class HGV extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner  title="HGV (Heavy Goods Transport)" pagename="HGV (Heavy Goods Transport)" bgimage={bnrimg}/>
                    <AboutCourierService aboutservice1 =  {aboutCourierService1}  />
                    <AboutCourierService2 aboutservice2 =  {aboutCourierService2}  />
                    
                    <OurWorkingProcess />
                    <WhyChooseUs title= "for HGV (Heavy Goods Transport)?" points={whyChooseUsPoints} />
                    <AboutCourierService3 aboutservice3= {aboutCourierService3}  />                                  
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default HGV;