import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';


export const photos = [
  {
    src: require('./../../images/gallery/26 tonne hiab.jpg'), width: 4, height: 3
  },
  {
    src: require('./../../images/gallery/Hiab 2.png'), width: 4, height: 3
  },
  {
    src: require('./../../images/gallery/18 tonne 1 (1).jpg'), width: 4, height: 3
  },
  {
    src: require('./../../images/gallery/18 tonne 2.jpg'), width: 4, height: 3
  },
  {
    src: require('./../../images/gallery/1.JPG'), width: 4, height: 3
  },
  { src: require('./../../images/gallery/2.JPG'),
    width: 4, height: 3
  },
  { src: require('./../../images/gallery/3.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/4.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/5.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/6.JPG'), width: 4, height: 3 },
  { src: require('./../../images/gallery/7.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/8.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/9.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/10.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/11.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/12.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/13.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/14.JPG'), width: 4, height: 3 },
  { src: require('./../../images/gallery/15.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/16.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/17.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/18.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/19.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/20.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/21.jpg'), width: 4, height: 3 },
  
  { src: require('./../../images/gallery/23.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/24.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/25.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/26.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/27.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/28.jpg'), width: 4, height: 3 },
  
  { src: require('./../../images/gallery/30.JPG'), width: 4, height: 3 },
  { src: require('./../../images/gallery/31.JPG'), width: 4, height: 3 },
  { src: require('./../../images/gallery/32.jpg'), width: 4, height: 3 },

  { src: require('./../../images/gallery/34.jpg'), width: 4, height: 3 },
 
  { src: require('./../../images/gallery/36.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/37.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/38.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/39.jpg'), width: 4, height: 3 },
  { src: require('./../../images/gallery/40.JPG'), width: 4, height: 3 },
];


var bnrimg = require('./../../images/banner/gallery.jpg');

class ServicesGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      viewerIsOpen: false,
    };
  }

  openLightbox = (event, { photo, index }) => {
    this.setState({ 
      currentImage: index,
      viewerIsOpen: true 
    });
  };

  closeLightbox = () => {
    this.setState({ 
      currentImage: 0,
      viewerIsOpen: false 
    });
  };

  render() {
    const { currentImage, viewerIsOpen } = this.state;
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="Gallery" pagename="Gallery" bgimage={bnrimg} />
          <Gallery photos={photos} onClick={this.openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={this.closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          <Footer />
        </div>
      </>
    );
  }
}

export default ServicesGallery;
